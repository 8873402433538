@import './../fonts.css';

.form-container {
    width: 80%;
    /* Adjust this value to get your desired width */
    margin: auto;
    /* other styles */
}


.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #fff;
}

.login-box {
    width: 34%;
}

h2 {
    margin-bottom: 10px;
    font-family: 'Gelion Medium';
}

p {
    margin-bottom: 20px;
    font-family: 'Gelion Light';
    font-size: 15px;
}

input[type="email"],
input[type="password"] {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    -webkit-transition: border 0.2s ease 0s, color 0.2s ease 0s;
    transition: border 0.2s ease 0s, color 0.2s ease 0s;
    box-sizing: border-box;
    font-size: 15px;
    margin-bottom: 20px;
    font-family: 'Gelion Regular';
}

input[type="email"]:focus,
input[type="email"]:focus-visible,
input[type="password"]:focus,
input[type="password"]:focus-visible {
    outline: 1.5px solid #333 !important;
}

input[type="submit"] {
    box-sizing: border-box;
    display: inline-block;
    line-height: calc(2.5 * 16px);
    border-radius: 6px;
    font-weight: 400;
    font-size: calc(0.875 * 16px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    text-transform: capitalize;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    transition: background-color 200ms ease 0ms, box-shadow 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
    position: relative;
    overflow: hidden;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    cursor: pointer;
    pointer-events: auto;
    box-shadow: none;
    --geist-ui-button-icon-padding: calc(0.727 * 16px);
    --geist-ui-button-height: calc(2.5 * 16px);
    --geist-ui-button-color: #fff;
    --geist-ui-button-bg: #000;
    min-width: calc(10.5 * 16px);
    width: 100%;
    height: calc(2.5 * 16px);
    padding: 0 calc(1.375 * 16px) 0 calc(1.375 * 16px);
    margin: 0 0 0 0;
}

input[type="submit"]:hover {
    background: #fff;
    color: #000;
    -webkit-transform: translate3d(0px,0px,0px);
    -ms-transform: translate3d(0px,0px,0px);
    transform: translate3d(0px,0px,0px);
}

.error-message{
    color: #f87d4e;
    font-family: 'Gelion Regular';
    font-size: 15px;
}

.password-input {
    position: relative;
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 18px;
  }
  
  .password-toggle:hover {
    color: #333;
  }
  
  .password-input-field {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    -webkit-transition: border 0.2s ease 0s, color 0.2s ease 0s;
    transition: border 0.2s ease 0s, color 0.2s ease 0s;
    box-sizing: border-box;
    font-size: 15px;
    margin-bottom: 20px;
    font-family: 'Gelion Regular';
  }
  
  .password-input-field:focus,
  .password-input-field:focus-visible {
    outline: 1.5px solid #333 !important;
  }
  