@import './fonts.css';


body {
  font-family: 'Gelion Regular';
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  margin: auto;
}

.header {
  background-color: #e9f1fc;
}

.header div {
  padding-bottom: 20px;
}

.header img {
  width: 70px !important;
  margin-top: 3%;
}

.header h1 {
  font-size: 24px;
  margin-bottom: 5px;
  font-family: 'Gelion Regular';
}

.header p {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 3%;
  font-family: 'Gelion Regular';
}

.content-header {
  margin-top: 40px;
  padding-bottom: 10px;
}

.header-title {
  font-family: 'Gelion Regular';
  font-size: 22px;
  line-height: 0.5em;
}

.header-date {
  font-family: 'Gelion Regular';
  font-size: 18px;
}

.columns {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1;
  margin: 0 10px;
}

.column-heading {
  background-color: #eaf1fc;
  border: 1px solid #d3e2fd;
  padding: 8px 20px;
  margin-bottom: 20px;
  text-align: center;
}

.task {
  margin-bottom: 20px;
}

.task-header {
  display: flex;
  align-items: flex-start;
}

.task-icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.task-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Gelion Regular';
}

.task-desc {
  margin: 0;
  font-size: 16px;
  margin-top: 8px;
  font-family: 'Gelion Light';
}

.user-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.user {
  padding: 4px;
  margin-right: 3px;
  font-family: 'Gelion Light';
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: #F4F4F4;
  border-radius: 8px;
  padding: 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  font-family: 'Gelion Light';
}

.logout-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: #fff;
  color: #000;
  border: none;
  padding: 8px;
  font-family: 'Gelion Regular';
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background: #000;
  color: #fff;
}