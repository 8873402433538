@font-face {
    font-family: 'Gelion Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Regular Italic'), url('fonts/gelion/Gelion Regular Italic.woff') format('woff');
}

@font-face {
    font-family: 'Gelion Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Regular'), url('fonts/gelion/Gelion Regular.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Light'), url('fonts/gelion/Gelion Light.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Thin Italic'), url('fonts/gelion/Gelion Thin Italic.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Light Italic'), url('fonts/gelion/Gelion Light Italic.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Thin'), url('fonts/gelion/Gelion Thin.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Medium Italic'), url('fonts/gelion/Gelion Medium Italic.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Medium'), url('fonts/gelion/Gelion Medium.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Semi Bold Italic'), url('fonts/gelion/Gelion SemiBold Italic.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Semi Bold'), url('fonts/gelion/Gelion SemiBold.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Bold Italic'), url('fonts/gelion/Gelion Bold Italic.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Bold'), url('fonts/gelion/Gelion Bold.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Black Italic'), url('fonts/gelion/Gelion Black Italic.woff') format('woff');
}


@font-face {
    font-family: 'Gelion Black';
    font-style: normal;
    font-weight: normal;
    src: local('Gelion Black'), url('fonts/gelion/Gelion Black.woff') format('woff');
}